// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/-components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-tmpl-article-js": () => import("./../../../src/pages/-tmpl-article.js" /* webpackChunkName: "component---src-pages-tmpl-article-js" */),
  "component---src-pages-tmpl-top-js": () => import("./../../../src/pages/-tmpl-top.js" /* webpackChunkName: "component---src-pages-tmpl-top-js" */),
  "component---src-templates-online-lesson-js": () => import("./../../../src/templates/online-lesson.js" /* webpackChunkName: "component---src-templates-online-lesson-js" */),
  "component---src-templates-other-page-js": () => import("./../../../src/templates/other-page.js" /* webpackChunkName: "component---src-templates-other-page-js" */),
  "component---src-templates-other-support-js": () => import("./../../../src/templates/other-support.js" /* webpackChunkName: "component---src-templates-other-support-js" */)
}

